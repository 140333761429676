<template>
  <div class="stock-card">
    <search class="mb-4" @inputChange="inputChange" />
    <div class="row">
      <div class="col-lg-3">
        <div class="categories">
          <h6 class="mb-3">Category</h6>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                :class="{ active: selectedCategory === null }"
                @click="setCurrentCategory(null)"
                >All</a
              >
            </li>
            <li v-for="(item, index) in categories" :key="index">
              <a
                href="javascript:void(0)"
                :class="{ active: isCategorySelected(item) }"
                @click="setCurrentCategory(item)"
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-9">
        <h6 class="mb-3">Products</h6>
        <vue-good-table
          class="custom-table-style possible-vertical-align"
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
            setCurrentPage: currentPage,
          }"
          :row-style-class="rowStyleClass"
          @on-page-change="onPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'">
              <div class="product-name">
                <div v-if="props.row.fileUrl" class="product-photo">
                  <img
                    :src="`${props.row.fileUrl}`"
                    :alt="`${props.formattedRow[props.column.field]}`"
                  />
                </div>
                <span> {{ props.formattedRow[props.column.field] }}</span>
              </div>
            </span>

            <table-number-input
              v-else-if="props.column.field == 'stock_update'"
              v-model="props.row.stock_update"
              @input="updateStock(props.row)"
              :tick="tick"
            ></table-number-input>

            <span
              v-else-if="props.column.field == 'priceWithTax'"
              :class="props.column.field"
            >
              {{ props.formattedRow[props.column.field] | price }}
            </span>

            <span
              v-else-if="props.column.field == 'oci'"
              :class="props.column.field"
            >
              {{ props.formattedRow[props.column.field] - props.row.stock_update }} ({{ props.formattedRow[props.column.field] }})
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <table-pagination
              v-model="currentPage"
              :total="pages"
              :per-page="perPage"
              :pageChanged="props.pageChanged"
              :perPageChanged="props.perPageChanged"
            />
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import Search from '@/components/Inputs/Search.vue'
import TableNumberInput from '@/components/Inputs/TableNumberInput.vue'
import TablePagination from '@/components/Table/TablePagination.vue'

export default {
  props: {
    stockType: { type: String, default: '' },
    car: { type: String, default: '' },
    carSizes: { type: Array, default: () => [] },
    warehouse: { type: String, default: '' },
    cart: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    VueGoodTable,
    TableNumberInput,
    TablePagination,
    Search,
  },
  watch: {
    stockType() {
      if (this.stockType === 'all') {
        this.columns = [
          {
            field: 'name',
            sortable: false,
          },
          {
            field: 'priceWithTax',
            type: 'decimal',
            sortable: false,
          },
          {
            field: 'inventoryQuantity',
            type: 'number',
            sortable: false,
          },
        ]
      } else if (this.stockType === 'car') {
        this.columns = [
          {
            field: 'name',
            sortable: false,
          },
          {
            field: 'priceWithTax',
            type: 'decimal',
            sortable: false,
          },
          {
            field: 'inventoryQuantity',
            type: 'number',
            sortable: false,
          },
          {
            field: 'oci',
            type: 'number',
            sortable: false,
          },
          {
            field: 'stock_update',
            type: 'number',
            sortable: false,
            width: '130px',
          },
        ]
      } else {
        this.columns = [
          {
            field: 'name',
            sortable: false,
          },
          {
            field: 'priceWithTax',
            type: 'decimal',
            sortable: false,
          },
          {
            field: 'inventoryQuantity',
            type: 'number',
            sortable: false,
          },
          {
            field: 'stock_update',
            type: 'number',
            sortable: false,
            width: '130px',
          },
        ]
      }
    },
    car() {
      this.setStockUpdate()
      this.setCarOci()
    },
    warehouse() {
      this.setStockUpdate()
    },
  },
  created() {
    this.loadOci()
    this.loadCategories()
    this.loadProducts()
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      perPage: 25,
      currentPage: 1,
      pages: 0,
      categories: [],
      selectedCategory: null,
      tick: 0,
      columns: [
        {
          field: 'name',
          sortable: false,
        },
        {
          field: 'priceWithTax',
          type: 'decimal',
          sortable: false,
        },
        {
          field: 'inventoryQuantity',
          type: 'number',
          sortable: false,
        },
        {
          field: 'stock_update',
          type: 'number',
          sortable: false,
          width: '130px',
        },
      ],
      rows: [],
      oci: null,
    }
  },
  methods: {
    loadProducts() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        enabled: true
      }
      if (this.selectedCategory !== null) {
        params['product.category'] = this.selectedCategory
      }
      if (this.search !== '') {
        params.search = this.search
      }
      this.$ProductVariants.getCollection({ params }, 'product_variant_stock_list').then(response => {
        this.rows = response.data['hydra:member']
        this.pages = response.data['hydra:totalItems']
        this.setStockUpdate()
        this.setCarOci()
      })
    },
    loadCategories() {
      this.$Categories.getCollection({}, 'category_list').then(response => {
        this.categories = response.data['hydra:member']
      })
    },
    loadOci() {
      this.$OptimalCarInventories
        .getCollection({
          params: { pagination: false },
        }, 'optimal_car_inventory_list')
        .then(response => {
          this.oci = response.data['hydra:member']
        })
    },
    setCarOci() {
      if (this.oci) {
        this.rows.forEach(product => {
          product.inventories.forEach(inventory => {
            if (inventory.car && inventory.car === this.car) {
              const size = this.carSizes[this.car]
              const oci = this.oci.find(
                o => o.variant === product['@id'] && o.size === size,
              )
              product.oci = oci.quantity
            }
          })
        })
      }
    },
    setCurrentCategory(item) {
      if (item === null) {
        this.selectedCategory = null
      } else {
        this.selectedCategory = item.id
      }
      this.loadProducts()
    },
    isCategorySelected(item) {
      if (
        item &&
        this.selectedCategory !== null &&
        item.id === this.selectedCategory
      ) {
        return true
      }
      return false
    },
    inputChange(value) {
      this.search = value
      this.loadProducts()
    },
    setStockUpdate() {
      if (this.cart.length) {
        this.rows.forEach(row => {
          let updated = false
          this.cart.forEach(product => {
            if (product.id === row.id) {
              row.stock_update = product.stock_update
              updated = true
            }
          })
          if (!updated) {
            row.stock_update = 0
          }
        })
      }

      if (this.stockType === 'car' || this.stockType === 'warehouse') {
        this.rows.forEach(row => {
          row.inventories.forEach(inventory => {
            if (inventory[this.stockType] === this[this.stockType]) {
              row.stock_update = inventory.available
            }
          })
        })
      }

      // trigger array change for vue
      const newRows = this.rows
      this.rows = []
      this.rows.push(...newRows)
      this.tick += 1
    },
    updateStock(product) {
      this.$emit('stockUpdate', product)
    },
    onPageChange() {
      if (this.tick > 0) {
        this.loadProducts()
      }
      this.tick += 1
    },
    rowStyleClass(row) {
      let styleClass = ''
      if (this.stockType === 'car' || this.stockType === 'warehouse') {
        row.inventories.forEach(inventory => {
          if (
            inventory[this.stockType] === this[this.stockType] &&
            inventory.available !== row.stock_update
          ) {
            styleClass = 'row-changed'
          }
        })
      }
      return styleClass
    },
  },
}
</script>
